import React from 'react';
import axios from 'axios';
import {useQueryClient} from '@tanstack/react-query';
import {useHistory} from 'react-router-dom';
import {processElenaValidationResult} from '../../../../helpers/validators';
import {AssigneeGroupAuthority, useRedirectIfNoPermission} from '../hooks';
import {useConfig, useUserConfig} from '../../../../hooks/useUserConfig';
import {MainCategory, trackEvent} from '../../../../analytics';
import AssigneeGroupForm, {transformToSlug} from './AssigneeGroupForm';
import {useQueryParams} from '@/hooks/useQueryParams.js';

const CreateAssigneeGroup = () => {
  useRedirectIfNoPermission(AssigneeGroupAuthority.EDIT);

  const queryClient = useQueryClient();
  const {fromMonitor} = useQueryParams();
  const userConfig = useUserConfig();
  const config = useConfig();
  const companyUuid = userConfig?.company?.uuid;
  const companyName = userConfig?.company?.name;
  const history = useHistory();

  const onSubmit = async (values) => {
    try {
      delete values.members;
      values.name = transformToSlug(values.name);
      await axios.post(`/api/elena/external-assignee-groups`, values);
      await queryClient.invalidateQueries(['assignee-groups']);

      trackEvent('Save new group', {
        mainCategory: MainCategory.ButtonClick,
        companyUuid,
        companyName,
      });

      if (
        fromMonitor &&
        config?.servers?.FE_CONFIG_MONITOR_SERVER !== undefined
      ) {
        const pageContext =
          fromMonitor === 'onboarding' || fromMonitor === 'support'
            ? fromMonitor
            : '';
        const queryParams =
          fromMonitor === 'onboarding' ? '?dueDateStart=&dueDateEnd=' : '';
        const redirectUrl = `${config?.servers?.FE_CONFIG_MONITOR_SERVER}/tickets/${pageContext}${queryParams}`;
        window.location.assign(redirectUrl);
      } else {
        history.push('/settings/assignee-groups');
      }
    } catch (e) {
      if (e.response?.status === 400) {
        return processElenaValidationResult(e.response.data);
      } else {
        console.error(e);
      }
    }
  };

  return (
    <AssigneeGroupForm
      header={{
        id: 'ASSIGNEE_GROUP_CREATE_HEADER',
      }}
      onSubmit={onSubmit}
      mode="create"
      initialValues={{members: [], externalCompanyUuid: companyUuid}}
    />
  );
};

export default CreateAssigneeGroup;
